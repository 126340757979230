import React from 'react';
import {
  FaTelegramPlane,
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaReact,
  FaCss3,
} from 'react-icons/fa';

import { AiOutlineAntDesign, AiOutlineApi } from 'react-icons/ai';
import {
  SiSass,
  SiJavascript,
  SiHtml5,
  SiMaterialUi,
  SiRedux,
  SiStyledComponents,
  SiExpo,
} from 'react-icons/si';

import Lets from 'assets/lets.png';
import sadmin from 'assets/adminsmart.png';
import edokon from 'assets/edokon.png';
import oscar from 'assets/oscar.png';
import iau from 'assets/iau.png';
import qalampir from 'assets/qalampiruz.png';
import iauadmin from 'assets/iauadmin.png';
import edm from 'assets/edm.png';
import student from 'assets/student.png';
import tdau from 'assets/tdau.png';
import tdauadmin from 'assets/tdauadmin.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: 'Asadbek Azamov',
  birthday: '12.05.1999',
  phone: '+99890-233-98-09',
  email: 'azamov980@gmail.com',
  address: 'Navoi region | Kyzyltepa district ',

  telegram: {
    link: 'https://t.me/AsadbekAzamov01',
    nickname: 'Азамов',
    icon: <FaTelegramPlane />,
  },
  socials: {
    instagram: {
      link: 'https://www.instagram.com/azamov_9909',
      nickname: 'Аsadbek Azamov',
      icon: <FaInstagram />,
    },
    github: {
      link: 'https://github.com/Azamov-Asadbek',
      nickname: 'Аsadbek Azamov',
      icon: <FaGithub />,
    },
    ln: {
      link: 'https://www.linkedin.com/in/asadbek-azamov-132073209',
      nickname: 'Аsadbek Azamov',
      icon: <FaLinkedin />,
    },
  },

  languageHeader: [
    {
      code: 'en',
      title: 'English',
      country_code: 'gb',
    },
    {
      code: 'uz',
      title: "O'zbekcha",
      country_code: 'uz',
    },
  ],

  skills: [
    {
      title: 'Html',
      imglink: '../img/html5.svg',
    },
    {
      title: 'Css',
      imglink: '../img/css3.svg',
    },
    {
      title: 'Sass',
      imglink: '../img/sass.svg',
    },
    {
      title: 'Bootstrap',
      imglink: '../img/bootstrap.svg',
    },
    {
      title: 'JavaSacript & Es6',
      imglink: '../img/javascript.svg',
    },
    {
      title: 'jQuerry',
      imglink: '../img/jquery.svg',
    },
    {
      title: 'Gulp',
      imglink: '../img/gulp.svg',
    },

    {
      title: 'React.js',
      imglink: '../img/react.svg',
    },
    {
      title: 'Figma',
      imglink: '../img/figma.svg',
    },
    {
      title: 'Material-ui',
      imglink: '../img/material-ui.svg',
    },
    {
      title: 'Firebase',
      imglink: '../img/firebase.svg',
    },
  ],

  portfolio: [
    {
      tag: 'Html & css',
      img: Lets,
      title: 'Lets.uz',
      texnos: [
        {
          icon: <SiHtml5 />,
          name: 'Html',
        },
        {
          icon: <SiJavascript />,
          name: 'Javascript',
        },
        {
          icon: <SiSass />,
          name: 'Sass',
        },
      ],
      links: [
        {
          url: 'https://lets.uz/',
        },
      ],
    },

    {
      tag: 'React',
      img: sadmin,
      title: 'Smart Camus | Admin',
      texnos: [
        {
          icon: <FaReact />,
          name: 'React',
        },
        {
          icon: <SiStyledComponents />,
          name: 'styled',
        },
        {
          icon: <SiMaterialUi />,
          name: 'MaterialUi',
        },
        {
          icon: <SiRedux />,
          name: 'Redux',
        },
      ],
      links: [
        {
          url: 'http://admin.smartcampus.uz/',
          github: 'https://github.com/Azamov-Asadbek/Azamov_Chat',
        },
      ],
    },

    {
      tag: 'React Native',
      img: edokon,
      title: "E-do'kon | android",
      texnos: [
        {
          icon: <SiExpo />,
          name: 'Expo',
        },
        {
          icon: <FaReact />,
          name: 'React native',
        },
        {
          icon: <AiOutlineApi />,
          name: 'Api',
        },
      ],
      links: [
        {
          url: 'https://expo.dev/artifacts/eas/5Do68E9bHLmgJGV7PWiXJB.aab',
        },
      ],
    },

    // {
    //   tag: 'Html & css',
    //   img: oscar,
    //   title: 'Oscaragency.uz',
    //   texnos: [
    //     {
    //       icon: <SiHtml5 />,
    //       name: 'Html',
    //     },
    //     {
    //       icon: <SiJavascript />,
    //       name: 'Javascript',
    //     },
    //     {
    //       icon: <SiSass />,
    //       name: 'Sass',
    //     },
    //   ],
    //   links: [
    //     {
    //       url: 'http://oscaragency.uz/',
    //     },
    //   ],
    // },

    {
      tag: 'React',
      img: iau,
      title: 'Iau.uz | Admission',
      texnos: [
        {
          icon: <FaReact />,
          name: 'React',
        },
        {
          icon: <SiStyledComponents />,
          name: 'styled',
        },
        {
          icon: <AiOutlineAntDesign />,
          name: 'Ant',
        },
      ],
      links: [
        {
          url: 'http://iau.agro.uz/',
        },
      ],
    },

    {
      tag: 'Html & css',
      img: qalampir,
      title: 'Qalampir.uz',
      texnos: [
        {
          icon: <SiHtml5 />,
          name: 'Html',
        },
        {
          icon: <SiJavascript />,
          name: 'Javascript',
        },
        {
          icon: <SiSass />,
          name: 'Sass',
        },
      ],
      links: [
        {
          url: 'http://qalampir.uz/',
        },
      ],
    },

    {
      tag: 'React',
      img: iauadmin,
      title: 'IAU | Admin',
      texnos: [
        {
          icon: <FaReact />,
          name: 'React',
        },
        {
          icon: <SiStyledComponents />,
          name: 'styled',
        },
        {
          icon: <AiOutlineAntDesign />,
          name: 'Ant',
        },
      ],
      links: [
        {
          url: 'http://iauadmin.agro.uz/',
        },
      ],
    },

    {
      tag: 'React',
      img: edm,
      title: 'EDM',
      texnos: [
        {
          icon: <FaReact />,
          name: 'React',
        },
        {
          icon: <SiStyledComponents />,
          name: 'styled',
        },
        {
          icon: <AiOutlineAntDesign />,
          name: 'Ant',
        },
      ],
      links: [
        {
          url: 'http://edm.agro.uz/',
        },
      ],
    },

    {
      tag: 'React',
      img: student,
      title: 'Smart campus | Student',
      texnos: [
        {
          icon: <FaReact />,
          name: 'React',
        },
        {
          icon: <SiStyledComponents />,
          name: 'styled',
        },
        {
          icon: <AiOutlineAntDesign />,
          name: 'Ant',
        },
      ],
      links: [
        {
          url: 'http://student.agro.uz/',
        },
      ],
    },

    {
      tag: 'React',
      img: tdau,
      title: 'TDAU.uz',
      texnos: [
        {
          icon: <FaReact />,
          name: 'React',
        },
        {
          icon: <SiStyledComponents />,
          name: 'styled',
        },
        {
          icon: <AiOutlineAntDesign />,
          name: 'Ant',
        },
      ],
      links: [
        {
          url: 'http://tdau.agro.uz/',
        },
      ],
    },

    {
      tag: 'React',
      img: tdauadmin,
      title: 'Tdau.uz | Admin',
      texnos: [
        {
          icon: <FaReact />,
          name: 'React',
        },
        {
          icon: <SiStyledComponents />,
          name: 'styled',
        },
        {
          icon: <AiOutlineAntDesign />,
          name: 'Ant',
        },
      ],
      links: [
        {
          url: 'http://tdauadmin.agro.uz/',
        },
      ],
    },
  ],
};

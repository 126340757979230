import React, { useState } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import ResumeData from '../../utils/ResumeData';
import { useTranslation } from 'react-i18next';
import { RiMailSendLine } from 'react-icons/ri';
import './contact.scss';
import '../Resume/resume.scss';

import MyButton from '../../components/Button/Button';
import emailjs from 'emailjs-com';
import Alert from '@material-ui/lab/Alert';
import MuiPhoneNumber from 'material-ui-phone-number';

function Contact() {
  const [alert, setAlert] = useState(false);
  const [phone, setPhone] = useState('');

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_t9jugv9',
        'template_pblj3nv',
        e.target,
        'user_04vEBH8Tc5ki02Xo9DvXa'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();

    setPhone('');

    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 10000);
  }

  function handleOnChange(value) {
    setPhone({
      phone: value,
    });
  }

  const { t } = useTranslation();
  return (
    <>
      <Grid container spacing={2} className="contact section">
        <Grid item xs={12} lg={7} className="mb-3">
          <Grid data-aos="zoom-out-up" item className="section_title">
            <span></span>
            <h6 className="title_text" variant="h6">
              {t('contact.contactf')}
            </h6>
          </Grid>
          <form onSubmit={sendEmail}>
            <Grid data-aos="zoom-out-right" item xs={12} className="pl-3">
              <Grid container spacing={4}>
                <Grid item xs={10} sm={5}>
                  <TextField
                    required
                    className="contact_inputs"
                    fullWidth
                    name="name"
                    label={t('contact.name')}
                  />
                </Grid>
                <Grid item xs={10} sm={5}>
                  <TextField fullWidth name="email" required label="E-mail" />
                </Grid>
                <Grid item xs={10}>
                  <MuiPhoneNumber
                    fullWidth
                    name="phone"
                    label="Phone Number"
                    defaultCountry={'uz'}
                    value={phone}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    name="text"
                    label={t('contact.massage')}
                    required
                    multiline
                    rows="4"
                  />
                </Grid>

                <Grid item xs={10}>
                  {alert ? (
                    <Alert className="mb-4 input_alert" severity="success">
                      {t('alert')}
                    </Alert>
                  ) : null}

                  <MyButton
                    type="submit"
                    text={t('contact.submit')}
                    icon={<RiMailSendLine />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid data-aos="zoom-out-left" item xs={12} lg={5}>
          <Grid container>
            <Grid data-aos="zoom-out-up" item className="section_title">
              <span></span>
              <h6 className="title_text" variant="h6">
                {t('contact.cinfo')}
              </h6>
            </Grid>

            <Grid className="mt-4" item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className="contactInfo_item">
                    <span>{t('AzamovInfo.address')}:</span>{' '}
                    {t('contact.c_address')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography className="contactInfo_item">
                    <span>{t('AzamovInfo.number')}:</span> {ResumeData.phone}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography className="contactInfo_item">
                    <span>E-mail:</span> {ResumeData.email}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container className="contact_socials">
                    {Object.keys(ResumeData.socials).map((key) => (
                      <Grid item>
                        <a href={ResumeData.socials[key].link} target="_blank">
                          {ResumeData.socials[key].icon}
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Contact;

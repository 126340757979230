import React from 'react';
import './button.scss';

import { Button } from '@material-ui/core';
const MyButton = ({ text, icon, link, type }) => {
  return (
    <>
      {link ? (
        <Button
          className="site-btn "
          href={link}
          target="_blank"
          endIcon={icon ? <div className="btn_icon">{icon}</div> : null}
        >
          <span className="btn_text">{text}</span>
        </Button>
      ) : (
        <Button
          className="site-btn "
          type={type}
          endIcon={icon ? <div className="btn_icon">{icon}</div> : null}
        >
          <span className="btn_text">{text}</span>
        </Button>
      )}
    </>
  );
};

export default MyButton;

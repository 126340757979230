import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import './i18next';

import App from './App';
import { ScaleLoader } from 'react-spinners';

ReactDOM.render(
  <Suspense
    fallback={
      <div className="loading_spinner">
        <ScaleLoader
          color={'#ffbe0f'}
          height={50}
          width={5}
          radius={3}
          margin={3}
        />
      </div>
    }
  >
    <App />
  </Suspense>,
  document.getElementById('root')
);

import './app.scss';
import { Container, Grid } from '@material-ui/core';

import ParticlesBg from 'particles-bg';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { lazy, useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Contact from './pages/Contact/Contact';

const ResumeAzamov = lazy(() => import('./pages/Resume/ResumeAzamov'));
const PortfolioAzamov = lazy(() => import('./pages/Portfolio/PortfolioAzamov'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const Header = lazy(() => import('./components/Header/Header'));
const AzamovProfil = lazy(() =>
  import('./components/ProfileAzamov/AzamovProfil')
);

function App() {
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, []);
  return (
    <>
      <Container className="all_component">
        <Grid container spacing={5}>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <AzamovProfil />
          </Grid>

          <Grid item xs>
            <Router>
              <Header />
              <main
                data-aos="fade-left"
                className="main_content container_shadow"
              >
                <Switch>
                  <Route path="/contact">
                    <Contact />
                  </Route>

                  <Route path="/portfolio">
                    <PortfolioAzamov />
                  </Route>

                  <Route path="/">
                    <ResumeAzamov />
                  </Route>
                </Switch>
              </main>
            </Router>
            <Footer />
          </Grid>
        </Grid>
        <ParticlesBg
          type="cobweb"
          color="#8c979a"
          bg={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        />
      </Container>
    </>
  );
}

export default App;
